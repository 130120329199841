.code-example {
  filter: $filter-drop-shadow-sm;
  &__content {
    position: relative;
    @include background-base();
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__source {
    z-index: -1;
    padding: 1rem 1.5rem;
    position: relative;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    margin-bottom: 1rem;
    background-color: $code-bg-light;
    @include themify($themes) {
      background-color: $code-bg-dark;
    }
  }
}

//prism overrides
code[class*="language-"] {
  text-shadow: none;
  @include themify($themes) {
    color: $text-code-light;
  }
}
.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  @include themify($themes) {
    background: rgba(255, 255, 255, 0.1);
  }
}

