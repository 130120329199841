$page-content-padding: em(10px);
$page-close-btn-width: em(40px);
$page-close-btn-padding-x: em(5px);
$page-close-btn-padding-y: em(10px);

.page-content.hide {
  & .page-content__close-btn {
    & i::before {
      transform: rotate(-180deg);
    }
  }
}

.page-content {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  right: 0;
  top: $navbar-main-real-height;
  min-height: $page-close-btn-width;
  @include background-base();
  border-radius: 0;
  padding: $page-close-btn-padding-y rem(15px);
  transition: all 0.2s ease;
  margin-top: $page-padding-top;
  &--init {
    transition: none;
    & .page-content__close-btn i:before{
      transition: none;
    }
  }
  &__body {
    position: relative;
    display: flex;
  }
  &__index {
    & > ul { // first ul level
      padding-left: 0;
      list-style-type: none;
      margin: 0;
    }
  }
  &__close-btn {
    display: flex;
    position: absolute;

    @include background-base();
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    box-sizing: border-box;

    filter: none;
    width: $page-close-btn-width;
    padding: $page-close-btn-padding-y $page-close-btn-padding-x;
    top: -$page-close-btn-padding-y;
    left: -$page-close-btn-width -$page-content-padding;
    font-size: rem(20px);
    font-weight: bold;
    cursor: pointer;
    & i {
      &:before {
        transition: transform .2s ease;
      }
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      flex-flow: column;
    }
  }
  &.sticky {
    top: 0;
    margin-top: 0;
  }
}