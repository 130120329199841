/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc; }

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc; }

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none; } }

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f5f2f0; }

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #999; }

.token.namespace {
  opacity: .7; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  /* This background color was intended by the author of this theme. */
  background: rgba(255, 255, 255, 0.5); }

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a; }

.token.function,
.token.class-name {
  color: #DD4A68; }

.token.regex,
.token.important,
.token.variable {
  color: #e90; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

div.code-toolbar {
  position: relative; }

div.code-toolbar > .toolbar {
  position: absolute;
  top: .3em;
  right: .2em;
  transition: opacity 0.3s ease-in-out;
  opacity: 0; }

div.code-toolbar:hover > .toolbar {
  opacity: 1; }

/* Separate line b/c rules are thrown out if selector is invalid.
   IE11 and old Edge versions don't support :focus-within. */
div.code-toolbar:focus-within > .toolbar {
  opacity: 1; }

div.code-toolbar > .toolbar .toolbar-item {
  display: inline-block; }

div.code-toolbar > .toolbar a {
  cursor: pointer; }

div.code-toolbar > .toolbar button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar button,
div.code-toolbar > .toolbar span {
  color: #bbb;
  font-size: .8em;
  padding: 0 .5em;
  background: #f5f2f0;
  background: rgba(224, 224, 224, 0.2);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: .5em; }

div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar span:hover,
div.code-toolbar > .toolbar span:focus {
  color: inherit;
  text-decoration: none; }

.error-template {
  padding: 40px 15px;
  text-align: center; }

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px; }

.error-actions .btn {
  margin-right: 10px; }

body {
  position: relative; }

.page-content.hide .page-content__close-btn i::before {
  transform: rotate(-180deg); }

.page-content {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 125px;
  min-height: 2.5em;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem;
  border-radius: 0;
  padding: 0.625em 0.9375rem;
  transition: all 0.2s ease;
  margin-top: 1.25rem; }
  .theme-dark .page-content {
    border: none;
    background-color: #262729; }
  .theme-lang-vi .page-content {
    border: none; }
  .page-content--init {
    transition: none; }
    .page-content--init .page-content__close-btn i:before {
      transition: none; }
  .page-content__body {
    position: relative;
    display: flex; }
  .page-content__index > ul {
    padding-left: 0;
    list-style-type: none;
    margin: 0; }
  .page-content__close-btn {
    display: flex;
    position: absolute;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.3125rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    box-sizing: border-box;
    filter: none;
    width: 2.5em;
    padding: 0.625em 0.3125em;
    top: -0.625em;
    left: -3.125em;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer; }
    .theme-dark .page-content__close-btn {
      border: none;
      background-color: #262729; }
    .theme-lang-vi .page-content__close-btn {
      border: none; }
    .page-content__close-btn i {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      flex-flow: column; }
      .page-content__close-btn i:before {
        transition: transform .2s ease; }
  .page-content.sticky {
    top: 0;
    margin-top: 0; }

.code-example {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1)); }
  .code-example__content {
    position: relative;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.3125rem;
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .theme-dark .code-example__content {
      border: none;
      background-color: #262729; }
    .theme-lang-vi .code-example__content {
      border: none; }
  .code-example__source {
    z-index: -1;
    padding: 1rem 1.5rem;
    position: relative;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    margin-bottom: 1rem;
    background-color: #f8f9fa; }
    .theme-dark .code-example__source {
      background-color: #000; }
    .theme-lang-vi .code-example__source {
      background-color: #000; }

code[class*="language-"] {
  text-shadow: none; }
  .theme-dark code[class*="language-"] {
    color: #e6e6e6; }
  .theme-lang-vi code[class*="language-"] {
    color: #e6e6e6; }

.theme-dark .token.operator, .theme-dark .token.entity, .theme-dark .token.url, .theme-dark .language-css .token.string, .theme-dark .style .token.string {
  background: rgba(255, 255, 255, 0.1); }

.theme-lang-vi .token.operator, .theme-lang-vi .token.entity, .theme-lang-vi .token.url, .theme-lang-vi .language-css .token.string, .theme-lang-vi .style .token.string {
  background: rgba(255, 255, 255, 0.1); }

.page {
  display: flex;
  flex-flow: column;
  padding-top: 1.25rem;
  flex: 1 1 auto; }
  .page--trader {
    position: relative;
    overflow: hidden;
    text-align: center;
    min-height: 100vh; }
  .page--login {
    padding-top: 0;
    flex-flow: row;
    justify-content: center; }

.layout-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap; }
