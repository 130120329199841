.page {
    display: flex;
    flex-flow: column;
    padding-top: $page-padding-top;
    flex: 1 1 auto;
    &--trader {
        position: relative;
        overflow: hidden;
        text-align: center;
        min-height: 100vh;
    }
    &--login {
        padding-top: 0;
        flex-flow: row;
        justify-content: center;
    }
}
.layout-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
}