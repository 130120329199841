// FTMO variables

//fonts
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;

//native
$html-font-size: 16px;// = 1 rem

//bootstrap crossover helper variables
$bs-gutter-x: calc(var(--bs-gutter-x)/ 2);
$bs-gutter-y: calc(var(--bs-gutter-y)/ 2);

//images
$login-logo-dark: url("/static/svg/default/ftmo_logo_login_dark.svg");
$login-logo-light: url("/static/svg/default/ftmo_logo_login_light.svg");

//transitions
$transition-zoom-in-time: 0.3s;
$transition-fade-in-time: 1s;

//main colors
$wfb-blue: #0781FE;
$wfb-green: #00C7B4;
$wfb-red: #FF3548;
$wfb-gray: #EAEAEA;
$wfb-yellow: #FFC700;
$dark-bg-main: #262729;
$light-bg-main: #fff;
$separator-color: #dee2e6;
$border-light-main: #dee2e6;
$border-dark-main: #343638;
$icon-light: #A2ACBD;
$icon-dark: #555B6C;
$code-bg-light: #f8f9fa;
$code-bg-dark: #000;
$nav-link-dark: #262729;

//headings
$heading-light: #fff;
$heading-dark: #000;

//main text
$wfb-font-family-base: $font-poppins;
$text-light: #A2ACBD;
$text-dark: #262729;

//main bg
$body-bg-light: #F4F4F4;
$body-bg-dark: #1E1E1E;

//forms
$wfb-input-bg-dark: #36373B;
$wfb-input-bg-light: #EAEAEA;
$wfb-input-border-dark: #36373B;
$wfb-input-border-light: #36373B;
$wfb-input-text-dark: $text-dark;
$wfb-input-text-light: $heading-light;

//socials
$facebook-bg: #1447A0;
$google-bg: #fff;
$apple-bg: #fff;

//navbar
$navbar-height: 75px;
$navbar-el-height: 35px;

//navbar--main
$navbar-main-p-top: 50px;
$navbar-main-real-height: $navbar-height + $navbar-main-p-top;

//sidebar


//other
$filter-drop-shadow: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
$filter-drop-shadow-sm: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

//check-box & radios
$checkbox-border-dark: #555B6C;
$checkbox-border-light: #fff;
$checkbox-input-width: em(32px);
$checkbox-input-bg: transparent;

$checkbox-input-checked-bg: transparent;

//HELPERS
//helpers-font-weight
//because figma.com uses keywords and I am lazy
$fw-thin: 100;
$fw-light: 200;
$fw-regular: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-demi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
